import axios from 'axios'
import { BASE_URL } from '../constants'

export const getLikes = async (slugs, type) => {
  try {
    const visitorID = localStorage.getItem('id')
    const res = await axios.post(`${BASE_URL}/likes/getLikes`, {
      visitorID,
      slugs
    }, { headers: { accept: 'Accept: application/json' } })
    if (res && res.data) return res.data
  } catch (error) {
    console.warn(error)
    throw error
  }
}

export const setLike = async (slug, type, like) => {
  try {
    const visitorID = localStorage.getItem('id')
    const res = await axios.post(`${BASE_URL}/likes/setLike`, {
      visitorID,
      slug,
      like
    }, { headers: { accept: 'Accept: application/json' } })
    return res
  } catch (error) {
    console.warn(error)
    throw error
  }
}
