import React, { memo, useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { setLike } from '../../services/likes'

import Img from '../Img'

import './Like.sass'
import likeIcon from '../../assets/img/likes/like.svg'
import unLikeIcon from '../../assets/img/likes/unlike.svg'

const Like = ({ like, likes, refetch, slug, type }) => {
  const likeBtnRef = useRef(null)
  const [likeCount, setLikeCount] = useState(likes)
  const [likeFlag, setLikeFlag] = useState(like)

  useEffect(() => {
    setLikeCount(likes)
    setLikeFlag(like)
  }, [like, likes])

  const _setLike = async (e) => {
    if (refetch) {
      e.stopPropagation()
      const visitorID = localStorage.getItem('id')
      if (visitorID) {
        likeBtnRef.current.disabled = true
        try {
          let newCount = likeCount
          if (likeFlag) {
            newCount -= 1
          } else {
            newCount += 1
          }
          setLikeCount(newCount)
          setLikeFlag(!likeFlag)
          const res = await setLike(slug, type, !likeFlag)
          if (res.status === 200) {
            refetch()
          }
          likeBtnRef.current.disabled = false
        } catch (error) {
          console.warn(error)
          likeBtnRef.current.disabled = false
          setLikeCount(likes)
          setLikeFlag(like)
        }
      }
    }
  }
  const icon = likeFlag ? likeIcon : unLikeIcon
  return (
    <div className={`likes small jp-accent${likeFlag ? ' doLike' : ''}${refetch ? ' clickable' : ''}`}>
      <button ref={likeBtnRef} className="likeBtn" onClick={_setLike} title="like">
        <Img src={icon} containerClass="likeIcon" contain/>
        <span>{likeCount}</span>
      </button>
    </div>
  )
}
export default memo(Like)

Like.propTypes = {
  slug: PropTypes.string.isRequired,
  likes: PropTypes.number.isRequired,
  like: PropTypes.bool.isRequired,
  refetch: PropTypes.func,
  type: PropTypes.number.isRequired
}
